.List {
    border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ListItemEven {
    background-color: #f8f8f0;
}
